// Gatsby supports TypeScript natively!
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon } from '@heroicons/react/outline'
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'


const services = [
  {
    title: 'Scratch and Paint Restoration Packages',
    answer:
      "When you need reliable scratch removal and paint restoration services in Seattle, look no further than Steam Seattle. Our technicians have a lot of experience repairing scratches, oxidation, and paint correction. Our experts are trained and equipped with the latest tools and products to provide the best scratch removal and paint restoration services to residents of Sammamish, Clyde Hill, Yarrow Point, Medina, Mercer Island and all other suburbs of Seattle. What’s more, we won’t keep your car for days. Most scratch removal and paint correction services are delivered in less than 3 hours and at just a small fraction of what traditional auto detailing providers would charge. Send in your details by filling in the form below, and one of our experts will be in touch with you shortly.",
  },
  {
    title: 'Scratch and Swirl Removal',
    answer:
      "When you drive your new car for the first time, nothing beats the feeling of joy and pride. You want everyone to see it, but every minute on the road poses danger to your car’s exterior and appearance. Road debris, loose gravel, road salt, construction, are all hazards to the original beauty of your car’s exterior. They create scratches and swirls that diminish the aesthetic value and appearance of your car. Scratches and swirls are unsightly and looking at them can make your cringe. Don’t fret though. Our Scratch Swirl Removal package is all you need. We targets all the deep gouges, scratches or small nicks on the roof, hoods, trunks and doors of your car to restore its original look and feel. We use advanced equipment and techniques to make sure the starch repair is perfect and unnoticeable. Our process leaves no indication or marks that the paint was corrected. Unbelievable as it may sound, all the work gets done in approximately 1 hour and it will only cost you as little as $50 per panel. Purchase this package and get rid of the annoying scratches on your car, increase its resale value and keep it looking fresh and new. All of that and more at pocket-friendly prices starting $50. Contact us using the form below, and let’s get started.",
  },
  {
    title: "Oxidation Removal",
    answer:
      "The UV radiation is unrelenting, not only on our skin but also on the body of your cars. It burns the exterior through a process called oxidation. That why over time, the plastic, rubber, vinyl, paint and other parts of your body turn dull, ending up being chalky. The bumpers and rubber seal appear whitish and hard. The Vinyl turns chalky and then it starts to crack. At the end, oxidation makes metallic and painted object looks years older than they are. Fortunately, with our Oxidation removal package, you don’t need to repaint your car. We clean all the oxidized paint and metal parts, leaving your car looking new and revitalized. The work takes an average 1 hour and based on the number of panels that have been oxidized, we charge a cost-friendly fee starting from $70. Our oxidation removal technicians stand behind their years of experience offering superior oxidation removal services to residents of Seattle. And our services are designed to save time, yield quality results, and to give your car a long-lasting, new-like appearance. Contact us by filling out the form below and let’s give your car or home a radiant luster.",
  },
  {
    title: 'Paint Correction',
    answer:
    "Keep your car looking fresh with our paint correction service. We have handcrafted this package for the budget-friendly car and homeowners who want their autos scratch-free and looking new always. The paint correction process takes 6-8 hours and depending on the state of your car it costs as low as $450. Surprisingly, most cars look 40% newer after the paint correction. The package price also includes the application of Nano Ceramic with a 1-year guarantee under the relentless rain of Seattle. The process starts by washing and clay bar the car. We then start the first phase of the correction, which gets rid of all swirl marks, light oxidation, minor water spots, and small scratches. The first step correction also seeks to improve the gloss and smoothness of the paint. We then apply the Nano Ceramic to deliver at least 40% reduction in scratches. Grab this package if you are looking for a cost-friendly way to freshen your car or home paint and tone down small defects such as swirl marks. It’s also a great purchase if you want to enhance the gloss. Otherwise, for comprehensive, up to 80% scratch removal, purchase our deluxe, paint correction package. Get your no-obligation quote now by filling out the form below.",
  },
  {
    title: 'Deluxe Paint Correction',
    answer:
      "This package offers comprehensive paint correction services that removes up to 80% of the scratches in the paint. The package is ideal for the passionate car owners that love the luster on the metallic or painted parts of their cars or homes and won’t settle for anything less. The process involves a series of correction steps that leave the paint fully level, allowing us to deliver unmatched gloss and shine. The deluxe paint correction removes all water sports, small scratch, medium scratches, light and deep oxidation, swirl marks, water spots, and some deep scratches. Do you know that more than 80% of the defects will be rectified with deluxe paint correction? You can follow it up by applying 3-year Nano ceramic to fully enhance the gloss and shine. Enroll for this package if you are looking for a way to fully give your paint or metallic surface a complete makeover. Your car will look 80% more attractive – and that is a guarantee. At Steam Seattle, there is nothing that makes us happier than seeing the broad smile on our clients face when we give them back their clean car. We offer superior services and save you time and money. Want to have a quick word? Please fill in the form below with your details and one of our experts will be in touch with you shortly.",
  }
]


const pricing = {
  tiers: [
    {
      title: 'Scratch Removal',
      price: 70,
      frequency: '/Per Panel',
      description: 'Scratch and Swirl Removal (Restoration)',
      features: ['1 hour'],
      cta: 'Call for Quote',
      mostPopular: false,
    },
    {
      title: 'Oxidation Removal',
      price: 90,
      frequency: '/Per Panel',
      description: 'Oxidation Removal From All Painted and Metal Surfaces',
      features: ['1 hour'],
      cta: 'Call for Quote',
      mostPopular: false,
    },
    {
      title: 'Deluxe Paint Correction',
      price: 865,
      frequency: '/Starting at',
      description: 'Paint Correction Removing 80% Of Scratches. Includes a 3-Year Nano Ceramics.',
      features: ['4 hours'],
      cta: 'Call for Quote',
      mostPopular: true,
    },
    {
      title: 'Paint Correction',
      price: 550,
      frequency: '/Starting at',
      description: 'Paint Correction Removing 40% Of Scratches. Includes a 1 Year Nano Ceramics.',
      features: ['3 hours'],
      cta: 'Call for Quote',
      mostPopular: false,
    },
  ],
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {
  return (
    <Layout>
    <Seo title="Scratch Removal and Paint Restoration Services" description="When you need reliable scratch removal and paint restoration services in Seattle, look no further than Steam Seattle." />


    <div className="relative bg-white pt-16 overflow-hidden">
      <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
        <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">

            <div className="mt-6">
              <h1 className="text-3xl font-extrabold tracking-tight text-gray-900">
              Scratch Removal<br/>and Paint Restoration Services
              </h1>
            </div>

        </div>
        <div className="mt-12 sm:mt-16 lg:mt-0">
          <div className="px-6 md:mr-6 lg:px-0 lg:m-0 lg:relative lg:h-full">
            <StaticImage
              src="../images/car-scratches.jpg"
              alt="car scratches"
              className="scratch-removal-image w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>


    <div className="max-w-7xl mx-auto py-4 px-4 sm:py-4 sm:px-6 lg:px-4">
      <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
        <dl className="mt-6 space-y-6 divide-y divide-gray-200">
          {services.map((service) => (
            <Disclosure as="div" key={service.title} className="pt-6">
              {({ open }) => (
                <>
                  <dt className="text-lg">
                    <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                      <span className="font-medium text-gray-900">{service.title}</span>
                      <span className="ml-6 h-7 flex items-center">
                        <ChevronDownIcon
                          className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                          aria-hidden="true"
                        />
                      </span>
                    </Disclosure.Button>
                  </dt>
                  <Disclosure.Panel as="dd" className="mt-2 pr-12">
                    <p className="text-base text-gray-500">{service.answer}</p>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
        </dl>
      </div>
    </div>



    {/* Tiers */}
      <div className="max-w-7xl mx-auto mt-6 space-y-6 lg:space-y-0 lg:grid lg:grid-cols-4 lg:gap-x-2 pb-16">
        {pricing.tiers.map((tier) => (
          <div
            key={tier.title}
            className="relative p-8 bg-white border border-gray-200 rounded-2xl shadow-sm flex flex-col"
          >
            <div className="flex-1">
              <h2 className="text-xl font-semibold text-gray-900">{tier.title}</h2>
              {tier.mostPopular ? (
                <p className="absolute top-0 py-1.5 px-4 bg-green-500 rounded-full text-xs font-semibold uppercase tracking-wide text-white transform -translate-y-1/2">
                  Most popular
                </p>
              ) : null}
              <p className="mt-4 flex items-baseline text-gray-900">
                <span className="text-5xl font-extrabold tracking-tight">${tier.price}</span>
                <span className="ml-1 text-xl font-semibold">{tier.frequency}</span>
              </p>
              <p className="mt-6 text-gray-500">{tier.description}</p>

              {/* Feature list */}
              <ul className="mt-6 space-y-6">
                {tier.features.map((feature) => (
                  <li key={feature} className="flex">
                    <CheckIcon className="flex-shrink-0 w-6 h-6 text-green-500" aria-hidden="true" />
                    <span className="ml-3 text-gray-500">{feature}</span>
                  </li>
                ))}
              </ul>
            </div>

            <a
              href="tel:877-578-3265"
              className={classNames(
                tier.mostPopular
                  ? 'bg-green-500 text-white hover:bg-green-600'
                  : 'bg-green-50 text-green-700 hover:bg-green-100',
                'mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium'
              )}
            >
              {tier.cta}
            </a>
          </div>
        ))}
      </div>


    </Layout>
  )
}
